import {
  ClientWrapper,
  ImageSlide,
  ImageSlider,
} from './experience.style';
import { graphql, useStaticQuery } from 'gatsby';

import Container from '../../../home/components/UI/Container';
import Logo from '../../../home/components/UIElements/Logo';
import React from 'react';
import Text from '../../../home/components/Text';

const Companies = () => {
  const data = useStaticQuery(graphql`
    query {
      aboutJson {
        experiences {
          clients {
            id
            logo {
              publicURL
            }
            name
            link
          }
        }
      }
    }
  `);

  const { clients } =
    data.aboutJson.experiences;

  return (

    <Container>
      <ClientWrapper>
        <div className="client__text">
          <Text as="span" content={'Companies who worked with us proudly'} />
        </div>
        <ImageSlider>
          <ImageSlide>
            {clients.map((item) => (
              <Logo
                key={`slide1__key${item.id}`}
                href={item.link}
                logoSrc={item.logo.publicURL}
                title={item.name}
              />
            ))}
          </ImageSlide>
          <ImageSlide>
            {clients.map((item) => (
              <Logo
                key={`slide2__key${item.id}`}
                href={item.link}
                logoSrc={item.logo.publicURL}
                title={item.name}
              />
            ))}
          </ImageSlide>
        </ImageSlider>
      </ClientWrapper>
    </Container>

  );
};

export default Companies;
