import * as React from 'react';

import {
	AboutDetails,
	AboutImage,
	AboutPageTitle,
	AboutWrapper,
	SocialProfiles,
} from './style';
import {
	IoLogoFacebook,
	IoLogoInstagram,
	IoLogoLinkedin,
	IoLogoTwitter,
} from 'react-icons/io';
import { graphql, useStaticQuery } from 'gatsby';

import AboutSection from './AboutSection';
import Companies from './Experience/companies';
import Experiences from './Experience';
import GatsbyImage from '../../components/gatsby-image';
import SocialProfile from '../../components/social-profile/social-profile';

const SocialLinks = [
	{
		icon: <IoLogoFacebook />,
		url: 'https://www.facebook.com/redqinc/',
		tooltip: 'Facebook',
	},
	{
		icon: <IoLogoInstagram />,
		url: 'https://www.instagram.com/redqinc/',
		tooltip: 'Instagram',
	},
	{
		icon: <IoLogoTwitter />,
		url: 'https://twitter.com/redqinc',
		tooltip: 'Twitter',
	},
	{
		icon: <IoLogoLinkedin />,
		url: 'https://www.linkedin.com/company/redqinc/',
		tooltip: 'Linked In',
	},
];

interface AboutProps { }

const About: React.FunctionComponent<AboutProps> = (props) => {
	const data = useStaticQuery(graphql`
		query {
			avatar: file(absolutePath: { regex: "/about.jpg/" }) {
				childImageSharp {
					gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
				}
			}
			site {
				siteMetadata {
					author
					about
				}
			}
		}
	`);

	return (
		<>
			<AboutPageTitle>
				<h1>About Algo Inc</h1>
			</AboutPageTitle>

			<AboutSection />

			<Experiences />

			<AboutWrapper>

				<AboutImage>
					<GatsbyImage src={data.avatar.childImageSharp.gatsbyImageData} alt='about' />
				</AboutImage>

				<AboutDetails>
					<h2>Hey there, what’s up?</h2>
					<p>
						RedQ Team is a creative agency specializing in building scalable,
						high-performance web & mobile application. Our main concern is
						creating more value into the application so that can help our
						customers to grow their business.
					</p>
					<p>
						RedQ Team is a creative agency specializing in building scalable,
						high-performance web & mobile application. Our main concern is
						creating more value into the application so that can help our
						customers to grow their business.
					</p>

					<SocialProfiles>
						<SocialProfile items={SocialLinks} />
					</SocialProfiles>
				</AboutDetails>
			</AboutWrapper>

			<Companies />
		</>

	);
};

export default About;
