import About from '../containers/about';
import Layout from '../containers/layout';
import React from 'react';
import SEO from '../components/seo';

type AboutPageProps = {};

const AboutPage: React.FunctionComponent<AboutPageProps> = () => {
  return (
    <Layout>
      <SEO
        title="About Us"
        description="A decade long of successful projects launched, from Frontend, Backend, Mobile, & DevOps. Our works speaks for themselves."
      />

      <About />
    </Layout>
  );
};

export default AboutPage;
